import React from "react";
import { Container } from "react-bootstrap";

class Footer extends React.Component {
  render() {
    return (
      <Container
        fluid
        className="px-md-0"
        style={{
          backgroundColor: "white",
          display: "flex",
          height: 50,
          position: "fixed",
          bottom: 0,
        }}
      >
        <div
          style={{
            margin: "auto auto",
            color: "grey",
            textAlign: "center",
          }}
        >
          <div>
            By signing up I agree to Huddle's{" "}
            <a
              href="https://huddleapp.com.au/terms-of-use"
              className="text-primary"
              style={{ textDecoration: "underline" }}
            >
              terms of use
            </a>{" "}
            and{" "}
            <a
              href="https://huddleapp.com.au/privacy-policy"
              className="text-primary"
              style={{ textDecoration: "underline" }}
            >
              privacy policy
            </a>
          </div>
        </div>
      </Container>
    );
  }
}
export default Footer;
