import React from "react";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import SideCover from "../../assets/images/super_dad.jpg";
import SignUpForm from "./SignUpForm";
import Loader from "../../components/common/Loader";

class Signup extends React.Component {
  state = {
    name: "",
    email: "",
    postcode: "",
    phone: "",
    couponCode: "",
    signup: false,
    loading: false,
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState === 4 && xhttp.status === 200) {
        this.setState({ signup: true, loading: false });
        this.props.history.push(`/success`);
      } else if (xhttp.readyState === 4 && xhttp.status >= 400) {
        const response = JSON.parse(xhttp.responseText);
        alert(response.message);
        this.setState({ signup: false, error: true, loading: false });
      } else if (xhttp.readyState === 4) {
        this.setState({ signup: false, loading: false });
      }
    };

    xhttp.open(
      "POST",
      "https://us-central1-huddleapp-prod.cloudfunctions.net/corporateWebSignUp/"
      //"https://us-central1-huddleapp-dev.cloudfunctions.net/corporateWebSignUp/"
    );
    xhttp.setRequestHeader("Content-Type", "application/json");
    const { name, email, postcode, phone, couponCode } = this.state;
    const { corpTitle = "" } = this.props;
    xhttp.send(
      JSON.stringify({
        name,
        email,
        postcode,
        phone,
        couponCode,
        corpTitle: corpTitle.replace(/[ ]/g, ""),
      })
    );
  };

  render() {
    const { signup, loading } = this.state;
    const {
      corpTitle,
      corpLogo,
      showThanks = true,
      corpText = "Create a Huddle account and enter your unique coupon code to start your Membership",
      sideBanner,
    } = this.props;

    return (
      <React.Fragment>
        {loading && (
          <React.Fragment>
            <Loader />
          </React.Fragment>
        )}
        <Container fluid className="mt-5 mb-5">
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-md-5 mt-2">
              <img src={sideBanner || SideCover} className="img-fluid" alt="" />{" "}
            </div>
            <div className="col-md-6">
              <div className="text-primary heading font-cabinBold">
                Book trusted babysitters,
                <br />
                when and where you need
              </div>
              <div className="sub-heading text-black mt-4 font-cabinBold">
                {corpText}
                {corpTitle && showThanks && (
                  <React.Fragment> thanks to {corpTitle}.</React.Fragment>
                )}
              </div>
              {corpLogo && (
                <div className="row justify-content-md-center corp_img mt-4">
                  <img
                    src={require("../../assets/images/" + corpLogo)}
                    alt="Logo"
                    height="120px"
                  />
                </div>
              )}
              <div className="row justify-content-md-center">
                <div className="col-md-9 mt-4">
                  <SignUpForm handleChange={this.handleChange} />
                </div>
              </div>
              <div className="row justify-content-md-center">
                <div className="col-md-6 mt-4">
                  <button
                    onClick={() => this.handleSubmit()}
                    type="button"
                    className="huddle-solid-button btn-block"
                  >
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                    Email me the download link!
                  </button>
                </div>
              </div>
              {signup && (
                <div className="row justify-content-md-center ">
                  <div className="col-md-9 mt-4">
                    <p className="text-center">
                      Woohoo! Thanks for signing up! <br />
                      Download the app and login using the email above to get
                      started.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
export default Signup;
