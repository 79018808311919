import React from "react";
import PropTypes from "prop-types";

class TextField extends React.Component {
  state = {
    value: ""
  };

  handleChangeText = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      value: value
    });
    if (this.props.onChangeText) {
      this.props.onChangeText(name, value);
    }
  };

  render() {
    const { labelText, type, placeHolder, helperText, name } = this.props;
    return (
      <div className="form-group huddle-text-input">
        <label className="font-cabinBold">{labelText}</label>
        <input
          name={name}
          type={type}
          className="form-control"
          placeholder={placeHolder}
          onChange={this.handleChangeText}
        />
        {helperText && (
          <small className="form-text text-muted">{helperText}</small>
        )}
      </div>
    );
  }
}

TextField.propTypes = {
  labelText: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeHolder: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  onChangeText: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
};

TextField.defaultProps = {
  type: "text",
  helperText: null
};

export default TextField;
