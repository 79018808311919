import React from "react";
import PropTypes from "prop-types";

class PhoneNumberField extends React.Component {
  state = {
    value: "",
  };

  handleChangeText = (event) => {
    const { intlCode = "+61 " } = this.props;
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      value: value,
    });
    if (this.props.onChangeText) {
      this.props.onChangeText(name, intlCode + value);
    }
  };

  render() {
    const {
      labelText,
      type,
      placeHolder,
      helperText,
      name,
      intlCode = "+61 ",
    } = this.props;
    return (
      <div
        className="form-group huddle-text-input"
        style={{ overflow: "hidden" }}
      >
        <label className="font-cabinBold">{labelText}</label>
        <div>
          <label
            className="font-cabinBold"
            style={{ position: "absolute", marginTop: "10px" }}
          >
            {intlCode}
          </label>
          <input
            style={{ paddingLeft: "30px" }}
            name={name}
            type={type}
            className="form-control"
            placeholder={placeHolder}
            onChange={this.handleChangeText}
          />
        </div>
        {helperText && (
          <small className="form-text text-muted">{helperText}</small>
        )}
      </div>
    );
  }
}

PhoneNumberField.propTypes = {
  labelText: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeHolder: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  onChangeText: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

PhoneNumberField.defaultProps = {
  type: "text",
  helperText: null,
};

export default PhoneNumberField;
