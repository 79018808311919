import React from "react";

const Loader = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: 1000,
        backgroundColor: "#00000055",
        backdropFilter: "blur(8px)",
      }}
    >
      <div
        style={{
          borderRadius: 10,
          overflow: "clip",
          width: 96,
          height: 96,
          position: "relative",
          top: "42%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div style={{ borderRadius: 10 }}>
          <img
            className="loading-anim"
            src="/logo192.png"
            alt="logo"
            width={96}
            style={{ borderRadius: 10 }}
          />
        </div>
      </div>
    </div>
  );
};
export default Loader;
