import React from "react";
import TextField from "../../components/common/TextField";
import PropTypes from "prop-types";
import PhoneNumberField from "../../components/common/PhoneNumberField";

class SignUpForm extends React.Component {
  render() {
    const { handleChange } = this.props;
    return (
      <form>
        <TextField
          labelText="Full Name"
          name="name"
          onChangeText={handleChange}
          type="text"
          placeHolder="Enter your full name"
        />
        <TextField
          labelText="Email"
          type="email"
          name="email"
          onChangeText={handleChange}
          placeHolder="Enter your email"
        />
        <TextField
          labelText="Postcode"
          type="text"
          placeHolder="Enter your postcode"
          name="postcode"
          onChangeText={handleChange}
        />
        <PhoneNumberField
          labelText="Mobile"
          type="text"
          placeHolder="Enter your mobile number"
          name="phone"
          onChangeText={handleChange}
        />
        <TextField
          labelText="Coupon Code"
          type="text"
          placeHolder="Enter coupon code"
          name="couponCode"
          onChangeText={handleChange}
        />
      </form>
    );
  }
}

SignUpForm.propTypes = {
  onChangeText: PropTypes.func.isRequired,
};
export default SignUpForm;
