import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import "./App.css";
import Signup from "./screens/Signup";
import Header from "./components/Header/Header";
import Success from "./screens/Success";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div>
      <Header />
      <BrowserRouter>
        <Route path="/success" render={(props) => <Success {...props} />} />
        <Route
          exact
          path="/airtree"
          render={(props) => (
            <Signup corpLogo="AirTree.jpeg" corpTitle="AirTree" {...props} />
          )}
        />
        <Route
          exact
          path="/deliveroo"
          render={(props) => (
            <Signup
              corpLogo="Deliveroo.png"
              corpTitle="Deliveroo"
              corpText={
                "Create a Huddle account and enter your coupon code to get free Member access until February 1st, 2021"
              }
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/twic"
          render={(props) => (
            <Signup
              corpTitle="TWIC"
              showThanks={false}
              corpText={
                "Create a Huddle account and enter your coupon code to redeem your 1 year Huddle membership"
              }
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/mumsandco"
          render={(props) => (
            <Signup
              corpTitle="mumsandco"
              showThanks={false}
              corpText={
                "Create a Huddle account and enter your coupon code to redeem your 1 month Huddle membership"
              }
              sideBanner={require("./assets/images/mumsandco.jpg")}
              {...props}
            />
          )}
        />
        <Route exact path="/" render={(props) => <Signup {...props} />} />
        <Route
          exact
          path="/twic/atlassian"
          render={(props) => (
            <Signup
              corpTitle="Atlassian"
              showThanks={false}
              corpText={
                "Create a Huddle account and enter your coupon code to redeem your 1 year Huddle membership"
              }
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/twic/twitch"
          render={(props) => (
            <Signup
              corpTitle="Twitch"
              showThanks={false}
              corpText={
                "Create a Huddle account and enter your coupon code to redeem your 1 year Huddle membership"
              }
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/twic/stripe"
          render={(props) => (
            <Signup
              corpTitle="Stripe"
              showThanks={false}
              corpText={
                "Create a Huddle account and enter your coupon code to redeem your 1 year Huddle membership"
              }
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/twic/zoom"
          render={(props) => (
            <Signup
              corpTitle="Zoom"
              showThanks={false}
              corpText={
                "Create a Huddle account and enter your coupon code to redeem your 1 year Huddle membership"
              }
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/twic/palo-alto-networks"
          render={(props) => (
            <Signup
              corpTitle="Palo Alto Networks"
              showThanks={false}
              corpText={
                "Create a Huddle account and enter your coupon code to redeem your 1 year Huddle membership"
              }
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/twic/collibra"
          render={(props) => (
            <Signup
              corpTitle="Collibra"
              showThanks={false}
              corpText={
                "Create a Huddle account and enter your coupon code to redeem your 1 year Huddle membership"
              }
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/twic/dropbox"
          render={(props) => (
            <Signup
              corpTitle="Dropbox"
              showThanks={false}
              corpText={
                "Create a Huddle account and enter your coupon code to redeem your 1 year Huddle membership"
              }
              {...props}
            />
          )}
        />
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
